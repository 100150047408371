import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";
import Switcher from "./Switcher";

const Footer = () => {
  const [state] = useState({
    links: [
      {
        title: "ADN",
        child: [
          { link: "#", title: "Inicio" },
          { link: "services", title: "Servicios" },
          { link: "#", title: "Caracteristicas" },
          { link: "#", title: "Equipo" },
          { link: "#", title: "Contacto" },
        ],
      },
    ],
  });

  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            {state.links.map((fLink, key) => (
              <Col key={key} lg={3} className="mt-4">
                <h4>{fLink.title}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    {fLink.child.map((fLinkChild, key) => (
                      <li key={key}>
                        <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </footer>
      {/* Render footer links */}
      <FooterLinks />

      {/* color switcher */}
      <Switcher />
    </React.Fragment>
  );
};

export default Footer;
