import React from "react";
import { Container, Row, Col } from "reactstrap";

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="vertical-content">
            <Col lg={5}>
              <div className="features-box">
                <h3>
                ADN es sinónimo de juventud, confianza y exclusividad.
                </h3>
                <p className="text-muted web-desc">
                Nos destacamos por
                </p>
                <ul className="text-muted list-unstyled mt-4 features-item-list">
                  <li className="">Innovación: Siempre a la vanguardia en servicios residenciales.</li>
                  <li className="">
                  Confiabilidad: Un equipo dedicado a su servicio.
                  </li>
                  <li className="">
                  Exclusividad: <br />Cada fraccionamiento, una obra de arte.
                  </li>
                  <li className="">Nuestra excelencia radica en nuestro capital humano, procesos avanzados y tecnología de punta.</li>
                </ul>
              </div>
            </Col>
            <Col lg={7}>
              <div className="features-img features-right text-end">
                <img
                  src="assets/images/online-world.svg"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
