import React, {  useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TestimonialsBox from "./TestimonialsBox";

const Testimonials = () => {
  const [state] = useState({
    clients: [
      {
        image: "assets/images/testimonials/user-2.jpg",
        title: "Sofía y Carlos ",
        subTitle: "Residentes desde 2022",
        desc: "Vivir en un fraccionamiento administrado por ADN ha sido una experiencia transformadora para nuestra familia. Desde el primer día, nos impresionó la atención al detalle y la calidad de los servicios. La jardinería es impecable, y la seguridad es de primer nivel. ",
      },
      {
        image: "assets/images/testimonials/user-1.jpg",
        title: "Alejandro ",
        subTitle: "Empresario y Residente",
        desc: "Como empresario, valoro la eficiencia y la profesionalidad, y eso es exactamente lo que ADN ofrece. Su equipo es siempre amable y proactivo, resolviendo cualquier inquietud de inmediato. Estoy impresionado con el nivel de servicio y la tranquilidad que ADN ha aportado a mi vida residencial.",
      },
      {
        image: "assets/images/testimonials/user-3.jpg",
        title: "Laura",
        subTitle: "Madre y Educadora",
        desc: "Elegir un hogar para mi familia fue una gran decisión, y ADN nos ha demostrado que hicimos la elección correcta. La calidad de vida que disfrutamos aquí es insuperable. Los espacios verdes son perfectos para que mis hijos jueguen y aprendan en un ambiente seguro y natural. ",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="section" id="testi">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Testimonios"
            desc="Escuche directamente de nuestros residentes. Testimonios reales, experiencias auténticas. Descubra cómo ADN ha transformado su vida residencial."
          />

          <Row className="mt-5">
            <TestimonialsBox clients={state.clients} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Testimonials;
