import React, { useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

const Process = ({ sectionClass }) => {
  const [state] = useState({
    services1: [
      {
        icon: "pe-7s-diamond",
        title: "Jardinería",
        desc: "Espacios verdes prístinos para un ambiente sereno.",
      },
      {
        icon: "pe-7s-display2",
        title: "Limpieza",
        desc: "Mantenimiento impecable para una vida ordenada.",
      },
      {
        icon: "pe-7s-piggy",
        title: "Recolección de Basura",
        desc: "Soluciones sostenibles para un entorno limpio.",
      },
    ],
    services2: [
      {
        icon: "pe-7s-science",
        title: "Vigilancia",
        desc: "Seguridad 24/7 para su tranquilidad.",
      },
      {
        icon: "pe-7s-news-paper",
        title: "Administración de Condominios",
        desc: "Gestión experta para una comunidad armoniosa.",
      },
      {
        icon: "pe-7s-plane",
        title: "Excelencia ",
        desc: "cada aspecto de su vida residencial con ADN",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className={"section " + sectionClass} id="services">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Nuestros Servicios"
            desc="Ofrecemos una gama completa de servicios para enriquecer su vida residencial. Desde jardinería meticulosa hasta vigilancia avanzada, nuestra gama de servicios incluye:"
          />

          <Row className="mt-4">
            {/* services box */}
            <ServiceBox services={state.services1} />
          </Row>

          <Row className="mt-4">
            {/* service box */}
            <ServiceBox services={state.services2} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
