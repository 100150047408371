import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";

const Process = () => {
  const [state] = useState({
    processes: [
      {
        icon: "pe-7s-pen",
        title: "Defina Su Ideal de Vivienda",
        desc: "Comparta con nosotros su visión de una vida residencial perfecta. Nuestro equipo escucha y planifica meticulosamente para satisfacer cada una de sus necesidades, desde jardines exuberantes hasta seguridad inquebrantable",
      },
      {
        icon: "pe-7s-id",
        title: "Reciba una Propuesta Personalizada",
        desc: "Con base en sus expectativas, le proporcionaremos una propuesta detallada. Nuestros presupuestos son transparentes y adaptados para garantizar el máximo retorno de su inversión en calidad de vida y plusvalía.",
      },
      {
        icon: "pe-7s-target",
        title: "Experimente la Excelencia en Servicios",
        desc: "Nuestro compromiso es entregar no solo lo prometido, sino superar las expectativas. Desde la administración eficiente hasta el mantenimiento meticuloso, cada entrega refleja nuestro estándar de oro.",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Nuestro Proceso de Trabajo"
            desc="En ADN, cada detalle de nuestro trabajo refleja la dedicación hacia la excelencia en la vida residencial. Desde la concepción hasta la ejecución, aquí está cómo creamos comunidades excepcionales:"
          />

          <Row>
            <Col lg={6} className="text-center process-left-icon-1">
              <i className="pe-7s-angle-right"></i>
            </Col>
            <Col lg={6} className="text-center process-left-icon-2">
              <i className="pe-7s-angle-right"></i>
            </Col>
          </Row>
          <Row className="mt-5">
            <ProcessBox processes={state.processes} />
            <div className="text-center mx-auto">
              <Link
                to="#"
                className="btn btn-primary waves-light waves-effect mt-5"
              >
                Vamos <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
